define(["./monitoringModule",
        "json!./monitoring.json"],
    function (monitoringModule, monitoring) {
        "use strict";

        monitoringModule.service("monitoringService", ["$http", "dataFetchers", function ($http, dataFetchers) {
            return {
                findMonitorableById: findMonitorableById,
                findMonitorableBySourceId: findMonitorableBySourceId,
                findMonitorableBySourceIds:findMonitorableBySourceIds,
                findMonitorableByIdWithAllFetchers: findMonitorableByIdWithAllFetchers,
                findMonitorableByIds: findMonitorableByIds,
                findMonitorableByOccurrenceId: findMonitorableByOccurrenceId,
                findRootMonitorablePresentationTreeById: findRootMonitorablePresentationTreeById,
                findMonitorableStatus: findMonitorableStatus,
                findDrivers: findDrivers,
                findFreeDrivers: findFreeDrivers,
                getMonitorableSearchUrl: getMonitorableSearchUrl,
                filter: filter,
                filterOccurrences: filterOccurrences,
                filterOccurrencesWithMonitorable: filterOccurrencesWithMonitorable,
                filterOccurrenceCategories: filterOccurrenceCategories,
                filterOccurrenceCauses: filterOccurrenceCauses,
                filterTransitionFailureReasons: filterTransitionFailureReasons,
                findOccurrenceById: findOccurrenceById,
                getNewInstanceOccurrenceCategory: getNewInstanceOccurrenceCategory,
                findOccurrenceCategoryById: findOccurrenceCategoryById,
                copyOccurrenceCategory: copyOccurrenceCategory,
                saveOccurrenceCategory: saveOccurrenceCategory,
                deleteOccurrenceCategory: deleteOccurrenceCategory,
                getNewInstanceOccurrenceCause: getNewInstanceOccurrenceCause,
                findOccurrenceCauseById: findOccurrenceCauseById,
                findOccurrenceCauseBySourceId: findOccurrenceCauseBySourceId,
                copyOccurrenceCause: copyOccurrenceCause,
                saveOccurrenceCause: saveOccurrenceCause,
                deleteOccurrenceCause: deleteOccurrenceCause,
                findOccurrenceComments: findOccurrenceComments,
                findTransitionComments: findTransitionComments,
                findTransitionFailureReasonsDefault: findTransitionFailureReasonsDefault,
                findOccurrenceCategories: findOccurrenceCategories,
                findOccurrenceCauses: findOccurrenceCauses,
                findPossibleAutomaticOccurrenceCauses:findPossibleAutomaticOccurrenceCauses,
                countPendingOccurrences: countPendingOccurrences,
                findOccurrencesDetails: findOccurrencesDetails,
                findOccurrenceStatus: findOccurrenceStatus,
                findPossibleOccurrenceStatus: findPossibleOccurrenceStatus,
                findInitialOccurrenceStatus: findInitialOccurrenceStatus,
                calibrateGeocode: calibrateGeocode,
                findTop5DelayedTrips: findTop5DelayedTrips,
                findTop5ExpensiveTrips: findTop5ExpensiveTrips,
                findTop5TripsWithMoreOccurrences: findTop5TripsWithMoreOccurrences,
                countOccurrencesByStatus: countOccurrencesByStatus,
                delayQuantizationOfTrips: delayQuantizationOfTrips,
                countDelayedTrips: countDelayedTrips,
                countActiveTripsWithFinancialImpact: countActiveTripsWithFinancialImpact,
                countActiveTripsWithOccurrences: countActiveTripsWithOccurrences,
                countActiveTrips: countActiveTrips,
                getExpectedTransitionsFilterUrl: getExpectedTransitionsFilterUrl,
                filterExpectedTransitions: filterExpectedTransitions,
                getMonitoringFollowupFilterUrl: getMonitoringFollowupFilterUrl,
                filterMonitoringFollowup: filterMonitoringFollowup,
                getMonitoringDeliveryOverviewFilterUrl: getMonitoringDeliveryOverviewFilterUrl,
                filterMonitoringDelivery: filterMonitoringDelivery,
                hasPermission: hasPermission,
                getContentExclusion: getContentExclusion,
                getRoutePathOfTrip: getRoutePathOfTrip,
                exportTransitionSpreadsheet: exportTransitionSpreadsheet,
                getPlannedRoutePathOfTrip: getPlannedRoutePathOfTrip,
                getEnum: getEnum,
                findByLikeSourceIdAndTypeActive: findByLikeSourceIdAndTypeActive,
                filterDrivers: filterDrivers,
                saveOrUpdateDriver: saveOrUpdateDriver,
                findDriverById: findDriverById,
                deleteDriver: deleteDriver,
                findTransitionsById: findTransitionsById,
                getDriverByMonitorableId: getDriverByMonitorableId,
                findLocalityById: findLocalityById,
                getNewInstanceLocality: getNewInstanceLocality,
                filterLocality: filterLocality,
                deleteLocality: deleteLocality,
                copyLocality: copyLocality,
                copyTransitionFailureReason: copyTransitionFailureReason,
                saveTransitionFailureReason: saveTransitionFailureReason,
                deleteTransitionFailureReason: deleteTransitionFailureReason,
                findTransitionFailureReasonById: findTransitionFailureReasonById,
                getNewInstanceTransitionFailureReason: getNewInstanceTransitionFailureReason,
                getKpiConfigurations: getKpiConfigurations,
                saveKpiConfigurations: saveKpiConfigurations,
                getLogRecordFilterUrl: getLogRecordFilterUrl,
                filterLogRecord: filterLogRecord,
                filterOccurrenceStatus: filterOccurrenceStatus,
                findOccurrenceStatusById: findOccurrenceStatusById,
                getNewInstanceOccurrenceStatus: getNewInstanceOccurrenceStatus,
                copyOccurrenceStatus: copyOccurrenceStatus,
                findAllIntermediateAndFinalStatuses: findAllIntermediateAndFinalStatuses,
                findAllTransitionFailureReasons: findAllTransitionFailureReasons,
                getOccurrenceCauseIcons: getOccurrenceCauseIcons,
                getTemperatureRangeConfiguration: getTemperatureRangeConfiguration,
                findTemperatureRangeConfigs: findTemperatureRangeConfigs,
                saveTemperatureConfigRange: saveTemperatureConfigRange,
                copyTemperatureRangeConfig: copyTemperatureRangeConfig,
                findTemperatureRangeConfigBySourceId: findTemperatureRangeConfigBySourceId,
                filterTemperatureRangeConfig: filterTemperatureRangeConfig,
                deleteTemperatureRangeConfig: deleteTemperatureRangeConfig,
                filterMonitorableTrackingSummary: filterMonitorableTrackingSummary,
                filterQuery: filterQuery,
                findQueryById: findQueryById,
                copyQuery: copyQuery,
                deleteQuery: deleteQuery,
                saveQuery: saveQuery,
                validateScript: validateScript,
                getParamTypes: getParamTypes,
                getFormatters: getFormatters,
                getCarriers: getCarriers,
                getVehicles: getVehicles,
                getTrucks: getTrucks,
                getPlates: getPlates,
                getDocks: getDocks,
                reschedule: reschedule,
                filterTemplateExpedition: filterTemplateExpedition,
                getMonitorableDetails: getMonitorableDetails,
                update: update,
                filterYardControl: filterYardControl,
                getYardControlConfiguration: getYardControlConfiguration,
                getEnumName: getEnumName,
                findAllSensors: findAllSensors,
                getTemperatureSensorConfigurations: getTemperatureSensorConfigurations,
                findSensorsByMonitorableIdAndDeviceIdAndProviderId: findSensorsByMonitorableIdAndDeviceIdAndProviderId,
                findSensorConfigurationByMonitorableAndIdentifierAndProvider: findSensorByMonitorableIdAndIdentifier,
                listProviders: listProviders,
                getTemperatureRangeConfigs: getTemperatureRangeConfigs,
                getEmailTemplate: getEmailTemplate,
                saveEmailTemplate: saveEmailTemplate,
                getVirtualFenceConfigurations: getVirtualFenceConfigurations,
                saveVirtualFenceConfigurations: saveVirtualFenceConfigurations,
                getTripConfiguration: getTripConfiguration,
                saveTripConfiguration: saveTripConfiguration,
                deleteTripConfiguration: deleteTripConfiguration,
                deleteMacro: deleteMacro,
                getNewInstanceMacro: getNewInstanceMacro,
                findMacroById: findMacroById,
                copyMacro: copyMacro,
                filterMacros: filterMacros,
                saveMacro: saveMacro,
                sendToTPF: sendToTPF,
            };

            function deleteDriver(id) {
                return $http.delete(servicePath("driver") + id).then(getData);
            }

            function findMonitorableById(id, fetchers) {
                return $http.get(servicePath("monitorableFindFetch"), {
                    params: {
                        monitorableId: id,
                        expand: fetchers
                    }
                }).then(getData);
            }

            function findMonitorableBySourceIds(ids, fetchers) {
                return $http.post(servicePath("monitorableFindFetch"), ids,{
                    params: {
                        expand: fetchers
                    }
                }).then(getData);
            }

            function findMonitorableBySourceId(sourceId, fetchers) {
                return $http.post(servicePath("monitorableFindBySourceId"), null, {
                    params: {
                        sourceId: sourceId,
                        expand: fetchers
                    }
                }).then(getData);
            }

            function findMonitorableByIdWithAllFetchers(id) {
                return findMonitorableById(id, dataFetchers.ALL);
            }

            function findRootMonitorablePresentationTreeById(id) {
                return $http.get(servicePath("rootMonitorablePresentationTree") + id).then(getData);
            }

            function findMonitorableByIds(ids, fetchers) {
                return $http.post(servicePath("monitorableFindByIds"), ids, {
                    params: {
                        expand: fetchers
                    }
                }).then(getData);
            }

            function findMonitorableByOccurrenceId(occurrenceId) {
                return $http.get(servicePath("monitorableFindByOccurrence") + occurrenceId).then(getData);
            }

            function findMonitorableStatus(id) {
                return $http.get(servicePath("monitorableStatus") + id).then(getData);
            }

            function findDriverById(id) {
                return $http.get(servicePath("driver") + id).then(getData);
            }

            function findLocalityById(id) {
                return $http.get(servicePath("localities") + id).then(getData);
            }

            function getNewInstanceLocality() {
                return $http.get(servicePath("newLocality")).then(getData);
            }

            function deleteLocality(id) {
                return $http.delete(servicePath("localityDelete") + id).then(getData);
            }

            function deleteTransitionFailureReason(id) {
                return $http.delete(servicePath("transitionFailureReasonDelete") + id).then(getData);
            }

            function copyLocality(id) {
                return $http.post(servicePath("localityCopy") + id).then(getData);
            }

            function filterLocality(service, template, firstResult, maxResults) {
                return $http.post(servicePath("localityFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function findTransitionFailureReasonById(id) {
                return $http.get(servicePath("transitionFailureReason") + id).then(getData);
            }

            function copyTransitionFailureReason(id) {
                return $http.post(servicePath("transitionFailureReasonCopy") + id).then(getData);
            }

            function saveTransitionFailureReason(transitionFailureReason) {
                return $http.post(servicePath("transitionFailureReason"), transitionFailureReason).then(getData);
            }

            function getNewInstanceTransitionFailureReason() {
                return $http.get(servicePath("newTransitionFailureReason")).then(getData);
            }

            function getMonitorableSearchUrl() {
                return monitoring.services["monitorable"];
            }

            function getKpiConfigurations() {
                return $http.get(servicePath("kpiConfigurations")).then(getData);
            }

            function saveKpiConfigurations(configurations) {
                return $http.post(servicePath("kpiConfigurations"), configurations).then(getData);
            }

            function filter(template, firstResult, maxResults) {
                return $http.post(servicePath("trip") + "filter", template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterDrivers(template, firstResult, maxResults) {
                return $http.post(servicePath("driver") + "filter", template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterTemperatureRangeConfig(service, template, firstResult, maxResults) {
                return $http.post(servicePath("temperatureRangeConfigFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function deleteTemperatureRangeConfig(configId) {
                return $http.delete(servicePath("temperatureRangeConfigDelete") + configId).then(getData);
            }

            function saveOrUpdateDriver(driver) {
                return $http.post(servicePath("driver"), driver).then(getData);
            }

            function filterOccurrenceCategories(service, template, firstResult, maxResults) {
                return $http.post(servicePath("occurrenceCategoriesFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterQuery(service, template, firstResult, maxResults) {
                return $http.post(servicePath("query") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterTransitionFailureReasons(template, firstResult, maxResults) {
                return $http.post(servicePath("transitionFailureReason") + "filter", template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterInvoices(template, firstResult, maxResults) {
                return $http.post(servicePath("invoice") + "filter", template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterOccurrenceCauses(service, template, firstResult, maxResults) {
                return $http.post(servicePath("occurrenceCausesFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterOccurrences(service, template, firstResult, maxResults) {
                return $http.post(servicePath("occurrences") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterOccurrencesWithMonitorable(service, template, firstResult, maxResults) {
                return $http.post(servicePath("occurrencesWithMonitorable") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function findOccurrenceById(id) {
                return $http.get(servicePath("occurrences") + "occurrence/" + id).then(getData);
            }

            function getNewInstanceOccurrenceCause() {
                return $http.get(servicePath("newOccurrenceCause")).then(getData);
            }


            function findOccurrenceCauseById(id) {
                return $http.get(servicePath("occurrenceCausesFilter") + "occurrenceCause/" + id).then(getData);
            }

            function findOccurrenceCauseBySourceId(sourceId) {
                return $http.get(servicePath("occurrenceCausesFilter") + "sourceId/" + sourceId).then(getData);
            }

            function copyOccurrenceCause(id) {
                return $http.post(servicePath("occurrenceCauseCopy") + id).then(getData);
            }

            function findQueryById(id) {
                return $http.get(servicePath("findQuery") + id).then(getData);
            }

            function copyQuery(id) {
                return $http.get(servicePath("copy") + id).then(getData);
            }

            function deleteQuery(id) {
                return $http.delete(servicePath("deleteQuery") + id).then(getData);
            }

            function saveQuery(script) {
                return $http.post(servicePath("newQuery"), script).then(getData);
            }

            function validateScript(script) {
                return $http.post(servicePath("validate"), script).then(getData);
            }

            function getParamTypes(script) {
                return $http.get(servicePath("param-types")).then(getData);
            }

            function getFormatters(script) {
                return $http.get(servicePath("formatters")).then(getData);
            }

            function deleteOccurrenceCause(id) {
                return $http.delete(servicePath("occurrenceCauseDelete") + id).then(getData);
            }

            function saveOccurrenceCause(occurrenceCause) {
                return $http.post(servicePath("occurrenceCauseSave"), occurrenceCause).then(getData);
            }

            function getNewInstanceOccurrenceCategory() {
                return $http.get(servicePath("newOccurrenceCategory")).then(getData);
            }

            function findOccurrenceCategoryById(id) {
                return $http.get(servicePath("occurrenceCategoriesFilter") + "occurrenceCategory/" + id).then(getData);
            }

            function copyOccurrenceCategory(id) {
                return $http.post(servicePath("occurrenceCategoryCopy") + id).then(getData);
            }

            function deleteOccurrenceCategory(id) {
                return $http.delete(servicePath("occurrenceCategoryDelete") + id).then(getData);
            }

            function saveOccurrenceCategory(occurrenceCategory) {
                return $http.post(servicePath("occurrenceCategorySave"), occurrenceCategory).then(getData);
            }

            function findOccurrenceComments(occurrenceId) {
                return $http.get(servicePath("commentOccurrence") + occurrenceId).then(getData);
            }

            function findTransitionComments(transitionId) {
                return $http.get(servicePath("commentTransition") + transitionId).then(getData);
            }

            function getEnum(enumType) {
                return $http({
                    url: servicePath("enum"),
                    method: "GET",
                    params: {enumType: enumType}
                }).then(getData);
            }

            function getEnumName(fullyQualifiedEnumName) {
                return fullyQualifiedEnumName.replace(/^.*\.(\w+)$/, "$1");
            }


            function findByLikeSourceIdAndTypeActive(sourceId, type) {
                return $http.post(servicePath("findByLikeSourceIdAndTypeActive"),
                    {
                        sourceId: sourceId,
                        type: type
                    }).then(getData);
            }

            function findTransitionsById(ids) {
                return $http.post(servicePath("transitionFindByIds"), ids).then(getData);
            }

            function findDrivers(partialName) {
                return $http.post(servicePath("findDrivers"), partialName).then(getData);
            }

            function findFreeDrivers(partialName){
                return $http.post(servicePath("findFreeDrivers"), partialName).then(getData);
            }

            function findOccurrenceCategories(partialName) {
                return $http.post(servicePath("occurrenceCategories"), null, {
                    params: {
                        string: partialName
                    }
                }).then(getData);
            }

            function findOccurrenceCauses(partialName, categoryId, monitorableId, monitorableType) {
                return $http.post(servicePath("occurrenceCauses"), null, {
                    params: {
                        string: partialName,
                        categoryId: categoryId,
                        monitorableId: monitorableId,
                        monitorableType: monitorableType
                    }
                }).then(getData);
            }

            function findPossibleAutomaticOccurrenceCauses(partialName, monitorableType, imageRequired) {
                return $http.post(servicePath("possibleAutomaticOccurrenceCauses"), null, {
                    params: {
                        string: partialName,
                    }
                }).then(getData);
            }

            function findOccurrenceStatus(viewValue) {
                return $http.get(servicePath("findOccurrenceStatus"), {params: {value: viewValue}}).then(getData);
            }

            function findPossibleOccurrenceStatus(occurrenceId) {
                return $http.get(servicePath("findPossibleOccurrenceStatus") + occurrenceId).then(getData);
            }

            function findInitialOccurrenceStatus(viewValue) {
                return $http.get(servicePath("findInitialOccurrenceStatus"), {params: {value: viewValue}}).then(getData);
            }

            function countPendingOccurrences(monitorableId) {
                return $http.get(servicePath("occurrences") + monitorableId + "/count", {
                    params: {"status": "PENDING"}
                }).then(getData);
            }

            function findOccurrencesDetails(monitorableId) {
                return $http.get(servicePath("occurrences") + monitorableId + "/occurrences-details").then(getData);
            }

            function calibrateGeocode(newLatLong) {
                return $http.post(servicePath("calibrateGeocode"), newLatLong).then(getData);
            }

            function findTop5DelayedTrips() {
                return $http.get(servicePath("tripOverview") + "delay").then(getData);
            }

            function findTop5ExpensiveTrips() {
                return $http.get(servicePath("tripOverview") + "cost").then(getData);
            }

            function findTop5TripsWithMoreOccurrences() {
                return $http.get(servicePath("tripOverview") + "occurrence").then(getData);
            }

            function countOccurrencesByStatus() {
                return $http.get(servicePath("tripOverview") + "occurrencesByStatus").then(getData);
            }

            function delayQuantizationOfTrips() {
                return $http.get(servicePath("tripOverview") + "delayQuantization").then(getData);
            }

            function countDelayedTrips() {
                return $http.get(servicePath("tripOverview") + "countDelayedTrips").then(getData);
            }

            function countActiveTripsWithFinancialImpact() {
                return $http.get(servicePath("tripOverview") + "countActiveTripsWithFinancialImpact").then(getData);
            }

            function countActiveTripsWithOccurrences() {
                return $http.get(servicePath("tripOverview") + "countActiveTripsWithOccurrences").then(getData);
            }

            function countActiveTrips() {
                return $http.get(servicePath("tripOverview") + "countActiveTrips").then(getData);
            }

            function hasPermission(permission) {
                return $http.get(servicePath("permission") + "/" + permission)
                    .then(getData);
            }

            function getContentExclusion() {
                return $http.get(servicePath("content-exclusion")).then(getData);
            }

            function getRoutePathOfTrip(tripId, firstResult, maxResult) {
                return $http.post(servicePath("tripRoute") + "/" + tripId, [], {
                    params: {
                        firstResult: firstResult,
                        maxResult: maxResult
                    }
                }).then(getData);
            }

            function getPlannedRoutePathOfTrip(tripId) {
                return $http.get(servicePath("plannedTripRoute") + "/" + tripId).then(getData);
            }

            function getDriverByMonitorableId(monitorableId) {
                return $http.get(servicePath("monitorable") + monitorableId + "/current-driver").then(getData);
            }

            function getExpectedTransitionsFilterUrl() {
                return monitoring.services.transitionOverview;
            }

            function filterExpectedTransitions(template, firstResult, maxResults) {
                return $http.post(servicePath("transitionOverviewFilter"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getMonitoringFollowupFilterUrl() {
                return monitoring.services.followupOverview;
            }

            function filterMonitoringFollowup(template, firstResult, maxResults) {
                return $http.post(servicePath("followupOverviewFilter"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getMonitoringDeliveryOverviewFilterUrl() {
                return monitoring.services.deliveryOverview;
            }

            function filterMonitoringDelivery(template, firstResult, maxResults) {
                return $http.post(servicePath("deliveryOverviewFilter"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function servicePath(serviceId) {
                return monitoring.baseUrl + monitoring.services[serviceId];
            }

            function getData(response) {
                return response.data;
            }

            function exportTransitionSpreadsheet(transitions) {
                return $http.post(monitoring.baseUrl + "/transitionSpreadsheet/export/", transitions)
                    .then(getData);
            }

            function getLogRecordFilterUrl() {
                return monitoring.services.logRecord;
            }

            function filterLogRecord(template, firstResult, maxResults) {
                return $http.post(servicePath("logRecordFilter"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function filterOccurrenceStatus(service, template, firstResult, maxResults) {
                return $http.post(servicePath("occurrenceStatusFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getNewInstanceOccurrenceStatus() {
                return $http.get(servicePath("newOccurrenceCategory")).then(getData);
            }

            function getOccurrenceCauseIcons() {
                return $http.get(servicePath("occurrenceCauseIcons")).then(getData);
            }

            function findOccurrenceStatusById(id) {
                return $http.get(servicePath("occurrenceStatusFilter") + "find/" + id).then(getData);
            }

            function copyOccurrenceStatus(id) {
                return $http.post(servicePath("occurrenceStatusCopy") + id).then(getData);
            }

            function findAllIntermediateAndFinalStatuses(id) {
                return $http.get(servicePath("occurrenceStatusFilter") + "findAllIntermediateAndFinalStatus", {params: {id: id}}).then(getData);
            }

            function findAllTransitionFailureReasons() {
                return $http.get(servicePath("transitionFailureReasonAll")).then(getData);
            }

            function findTransitionFailureReasonsDefault() {
                return $http.get(servicePath("transitionFailureReasonDefault")).then(getData);
            }

            function getTemperatureRangeConfiguration(id) {
                return $http.get(servicePath("temperatureRangeOrDefault") + id).then(getData);
            }

            function findTemperatureRangeConfigs() {
                return $http.get(servicePath("temperatureRangeConfigs")).then(getData);
            }

            function findTemperatureRangeConfigBySourceId(sourceId) {
                return $http.get(servicePath("findTemperatureRangeConfigBySourceId") + sourceId).then(getData);
            }

            function copyTemperatureRangeConfig(temperatureRangeTypeId) {
                return $http.get(servicePath("temperatureRangeConfigCopy") + temperatureRangeTypeId).then(getData);
            }

            function saveTemperatureConfigRange(config) {
                return $http.post(servicePath("temperatureRangeConfigSave"), config).then(getData);
            }

            function filterMonitorableTrackingSummary(template, firstResult, maxResults) {
                return $http.post(servicePath("monitorableTrackingSummary"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getDocks(viewValue) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.docks, {
                    params: {viewValue: viewValue}
                }).then(getData);
            }

            function getPlates(viewValue) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.plates, {
                    params: {viewValue: viewValue}
                }).then(getData);
            }

            function getVehicles(viewValue) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.vehicles, {
                    params: {viewValue: viewValue}
                }).then(getData);
            }

            function getTrucks(viewValue) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.trucks, {
                    params: {viewValue: viewValue}
                }).then(getData);
            }

            function getCarriers(viewValue) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.carriers, {
                    params: {viewValue: viewValue}
                }).then(getData);
            }

            function reschedule(schedule) {
                return $http.post(monitoring.baseUrl + monitoring.services.expedition.reschedule, schedule).then(getData);
            }

            function filterTemplateExpedition(service, template, firstResult, maxResults, onlyOrigins) {
                return $http.post(monitoring.baseUrl + monitoring.services.expedition.baseUrl + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults,
                        onlyOrigins: onlyOrigins
                    }
                }).then(getData);
            }

            function getMonitorableDetails(monitorableSourceId) {
                return $http.get(monitoring.baseUrl + monitoring.services.expedition.tripDetails + monitorableSourceId).then(getData);
            }

            function update(restExpedition) {
                return $http.post(monitoring.baseUrl + monitoring.services.expedition.updateExpedition, restExpedition).then(getData);
            }

            function filterYardControl(service, template, firstResult, maxResults) {
                return $http.post(monitoring.baseUrl + monitoring.services.yardControl.baseUrl + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getYardControlConfiguration() {
                return $http.get(monitoring.baseUrl + monitoring.services.yardControl.configuration).then(getData);
            }

            function findAllSensors() {
                return $http.get(servicePath("sensorConfiguration") + "/find").then(getData);
            }

            function getTemperatureSensorConfigurations(monitorableId) {
                return $http.get(servicePath("sensorConfiguration") + "/temperature/get/" + monitorableId).then(getData);
            }

            function findSensorsByMonitorableIdAndDeviceIdAndProviderId(monitorableId, deviceId, providerId) {
                return $http.get(servicePath("sensorConfiguration") + "/find/specific", {
                    params: {
                        monitorableId: monitorableId,
                        deviceId: deviceId,
                        providerId: providerId
                    }
                }).then(getData);
            }

            function findSensorByMonitorableIdAndIdentifier(monitorableId, identifier, providerId) {
                return $http.get(servicePath("sensorConfiguration") + "/find-by-monitorable-and-identifier", {
                    params: {
                        monitorableId: monitorableId,
                        identifier: identifier,
                        providerId: providerId
                    }
                }).then(getData);
            }

            function listProviders() {
                return $http.get("/cockpit-gateway/tracking/providers/getInfoWithoutMobile").then(getData);
            }

            function getTemperatureRangeConfigs() {
                return $http.get("/cockpit-gateway/monitoring/temperature-range-config/configs").then(getData);
            }

            function getEmailTemplate() {
                return $http.get(monitoring.baseUrl + monitoring.services.email.getEmailTemplate).then(getData);
            }

            function saveEmailTemplate(template) {
                return $http.post(monitoring.baseUrl + monitoring.services.email.saveEmailTemplate, template).then(getData);
            }

            function getVirtualFenceConfigurations() {
                return $http.get(monitoring.baseUrl + monitoring.services.virtualFence.getConfigurations).then(getData);
            }

            function saveVirtualFenceConfigurations(configurations) {
                return $http.post(monitoring.baseUrl + monitoring.services.virtualFence.saveConfigurations, configurations).then(getData);
            }

            function getTripConfiguration(monitorableId) {
                return $http.get(monitoring.baseUrl + monitoring.services.monitorableConfiguration + "/find/" + monitorableId).then(getData);
            }

            function saveTripConfiguration(configuration) {
                return $http.post(monitoring.baseUrl + monitoring.services.monitorableConfiguration, configuration).then(getData);
            }

            function deleteTripConfiguration(configuration) {
                return $http.delete(monitoring.baseUrl + monitoring.services.monitorableConfiguration, configuration).then(getData);
            }

            function deleteMacro(id) {
                return $http.delete(servicePath("macroDelete") + id).then(getData);
            }

            function getNewInstanceMacro() {
                return $http.get(servicePath("newMacro")).then(getData);
            }

            function findMacroById(id) {
                return $http.get(servicePath("macrosFilter") + "macro/" + id).then(getData);
            }

            function copyMacro(id) {
                return $http.post(servicePath("macroCopy") + id).then(getData);
            }

            function filterMacros(service, template, firstResult, maxResults) {
                return $http.post(servicePath("macrosFilter") + service, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function saveMacro(macro) {
                return $http.post(servicePath("macroSave"), macro).then(getData);
            }

            function sendToTPF(invoice) {
                return $http.post(servicePath("tpf"), invoice).then(getData);
            }
        }]);
    });
