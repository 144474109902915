define(["../occurrencesModule", "text!./NewOccurrenceModal.html"], function (occurrencesModule, newOccurrenceModalTemplate) {
    "use strict";

    occurrencesModule.service("newOccurrenceModal", [
        "$modal",
        function ($modal) {
            return function (monitorable) {
                return $modal.open({
                    template: newOccurrenceModalTemplate,
                    size: "lg",
                    controller: ["$scope", function ($scope) {
                        $scope.monitorable = monitorable;
                        $scope.onSave = function (occurrenceId) {
                            $scope.$close(occurrenceId);
                        };
                        $scope.onBack = function () {
                            $scope.$dismiss();
                        };
                    }]
                }).result;

            };
        }]);
});