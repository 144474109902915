define([
    "angular",
    "front-end-web-commons",
    "./fetcher/fetcher",
    "./completion/completion",
    "./comment/comment",
    "./deliveryOverview/monitoringDeliveryOverview",
    "./monitoringConfiguration/monitoringConfiguration",
    "./transitionSpreadsheet/transitionSpreadsheet",
    "./monitorable/monitorable",
    "./monitoringNodeCommands/monitoringNodeCommands",
    "./transitions/transitions",
    "./occurrenceListener/occurrenceListener",
    "./occurrences/occurrences",
    "./occurrenceCategory/occurrenceCategory",
    "./transitionFailureReason/transitionFailureReason",
    "./occurrenceCause/occurrenceCause",
    "./userPermissions/userPermissions",
    "./locality/monitoringLocality",
    "./logRecord/logRecord",
    "./occurrenceStatus/occurrenceStatus",
    "front-end-web-commons/app/nlgSaveAndContinue/nlgSaveAndContinue",
    "./temperature/temperature",
    "./temperatureRangeConfig/temperatureRangeConfig",
    "./sensor/SensorConfiguration",
    "./tripOverview/monitoringTripOverview",
    "./tripStartOverview/monitoringTripStartOverview",
    "./monitorableTemperatureOverview/monitoringTemperatureOverview",
    "./monitorableFollowupDashboardOverview/monitorableFollowupDashboardOverview",
    "./carrierTripOverview/monitoringCarrierTripOverview",
    "./invoiceDeliveryEffectivenessOverview/monitoringInvoiceDeliveryEffectivenessOverview",
    "./workHoursControl/workHoursControl",
    "./yardControl/monitoringYardControl",
    "front-end-web-commons/app/cityStateFormat/cityStateFormat",
    "./email/email",
    "./virtualFence/virtualFence",
    "./lgpd/monitoringLGPD",
    "front-end-web-commons/app/nlgLGPD/nlgLGPD",
    "./kpiConfigurations/kpiConfigurations",
    "./monitoringFollowupOverview/monitoringFollowupOverview",
    "./macro/macro",
    "./clientViewConfiguration/clientViewConfiguration",
    "./restrictionConfiguration/restrictionConfiguration",
    "front-end-web-commons/app/tdnHelp/tdnHelp",

], function (angular) {
    "use strict";
    return angular.module("monitoringModule", [
        "ui.router",
        "ui.bootstrap",
        "fetcherModule",
        "filterModule",
        "remoteModule",
        "entityDTOModule",
        "mapsModule",
        "dashboardModule",
        "trackingMobileModule",
        "trackingModule",
        "nlgTableModule",
        "nlgTableDynamicColumnsModule",
        "xlsModule",
        "chartingModule",
        "completionModule",
        "nlgMultiSelectModule",
        "commentModule",
        "nlgBasketModule",
        "transitionSpreadsheetModule",
        "monitoringConfigurationModule",
        "monitorableModule",
        "monitoringNodeCommandsModule",
        "transitionsModule",
        "occurrenceListenerModule",
        "occurrencesModule",
        "occurrenceCategoryModule",
        "transitionFailureReasonModule",
        "occurrenceCauseModule",
        "userPermissionsModule",
        "monitoringLocalityModule",
        "logRecordModule",
        "occurrenceStatusModule",
        "nlgSaveAndContinueModule",
        "temperatureModule",
        "temperatureRangeConfigModule",
        "SensorConfigurationModule",
        "monitoringConfigurationModule",
        "monitoringTripOverviewModule",
        "monitoringTripStartOverviewModule",
        "monitoringTemperatureOverviewModule",
        "monitorableFollowupDashboardOverviewModule",
        "monitoringCarrierTripOverviewModule",
        "monitoringDeliveryOverviewModule",
        "monitoringOccurrenceOverviewModule",
        "monitoringInvoiceDeliveryEffectivenessOverviewModule",
        "monitoringQueryModule",
        "workHoursControlModule",
        "monitoringYardControlModule",
        "cityStateFormatModule",
        "emailModule",
        "virtualFenceModule",
        "monitoringLGPDModule",
        "nlgLGPDModule",
        "kpiConfigurationsModule",
        "monitoringFollowupOverviewModule",
        "macroModule",
        "clientViewConfigurationModule",
        "restrictionConfigurationModule",
        "tdnHelpModule"
    ]);
});
