define([
    "../temperatureRangeConfig/temperatureRangeConfigModule",
    "./monitoringConfigurationModule",
    "angular",
    "text!./monitoringVelocityConfigurationTemplate.html"
], function (temperatureRangeConfigModule, configurationModule, angular, template) {
    "use strict";

    return configurationModule.service("monitoringVelocityConfigurationModal", [
        "$modal",
        "monitoringService",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        function ($modal, monitoringService, messagesModal, loadingService, remoteExceptionHandler) {
            return function (deviceByMonitorable) {
                return loadingService(monitoringService.getTripConfiguration(deviceByMonitorable.monitorable.id))
                    .catch(remoteExceptionHandler())
                    .then(function (configuration) {
                        return $modal.open({
                            template: template,
                            size: "md",
                            controller: ["$scope", function ($scope) {
                                $scope.deviceByMonitorable = deviceByMonitorable;
                                $scope.isNotStatusCreated = !deviceByMonitorable.isStatusCreated();
                                $scope.model = configuration;

                                $scope.save = function () {
                                    return loadingService(monitoringService.saveTripConfiguration($scope.model))
                                        .catch(remoteExceptionHandler())
                                        .then(function () {
                                            return messagesModal("dialog.success", ["monitoring.config.saved"])
                                        })
                                        .then(function () {
                                            return $scope.$close();
                                        });
                                };
                            }]
                        }).result;
                    });
            };

        }]);
});