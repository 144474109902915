define([
        "./trackingModule",
        "text!./trackingMonitorablesList.html"],
    function (trackingModule, template) {
        "use strict";

        return trackingModule.service("trackingMonitorableList", [
            "$http",
            "$modal",
            "$state",
            function ($http, $modal, $state) {
                return function (monitorables) {

                    return $modal.open({
                        template: template,
                        size: "m",
                        controller: ["$scope", "$window", function ($scope,$window) {

                            $scope.tableOptions = {
                                records: monitorables,
                                columns: [
                                    {
                                        header: "type",
                                        cellName: "{{monitorable.type | translate}}",
                                        width: "10%",
                                        cell: {
                                            template: "<span translate>monitorable.type.{{record.type.toLowerCase()}}</span>"
                                        }
                                    },
                                    {
                                        header: "monitorable",
                                        cellName: "monitorable",
                                        width: "10%",
                                        cell: {
                                            template: "<a name='record.sourceId' ng-click='goToMonitorable(record.id)'><span>{{record.sourceId}}</span></a>"
                                        }
                                    }
                                ]
                            };

                            $scope.goToMonitorable = function (id) {
                                const href = $state.href("monitoring.monitorableEdition", {
                                    monitorableId: id
                                });
                                $window.open(href,'_blank');
                            };
                        }]
                    });
                };
            }]);
    });