define(["./commentModule",
    "text!./commentDetails.html",
    "text!./commentDetailsModal.html"], function (commentModule, template, modalTemplate) {
    "use strict";

    commentModule.directive("commentDetails", ["commentService", function (commentService) {
        return {
            restrict: "E",
            scope: {
                comment: "<value",
                urlDownloadAttachments: "<?",
                monitorableSourceId: "="
            },
            template: template,
            controller: ["$scope", "$modal","messagesModal", function ($scope, $modal, messagesModal) {
                $scope.hasAttachments = function () {
                    return $scope.urlDownloadAttachments && $scope.comment.hasAttachments;
                };

                $scope.downloadAttachments = function () {
                    return commentService.downloadAttachments($scope.urlDownloadAttachments + "/downloadAttachments", $scope.comment, $scope.monitorableSourceId);
                };

                $scope.viewFile = function () {
                    commentService.downloadFile($scope.urlDownloadAttachments).then((function (response) {
                        const data = response.data;
                        const fileName = response.headers("File-Name")
                        // não sabemos ainda se vamos seguir mostrando o pdf
                        // const isPdfFile = fileName.includes(".pdf")
                        const isPngFile = fileName.includes(".png")
                        const isJpegFile = fileName.includes(".jpeg") || fileName.includes(".jpg")
                        const isGifFile = fileName.includes(".gif")
                        if ( isPngFile || isJpegFile || isGifFile) {
                            openImageModal(isPngFile, isJpegFile, isGifFile, data)
                            return
                        }
                        return messagesModal("dialog.warning", [{
                            keyBundle: "comment.details.file.not.viewable",
                            parameters: []
                        }]);
                    })).catch(function (errorResponse){
                        if(errorResponse.status == 501){
                            messagesModal("dialog.warning", [{
                                keyBundle: "comment.details.multiple.files",
                                parameters: []
                            }]);
                            return
                        }
                        messagesModal("dialog.error", [{
                            keyBundle: "logging.error.msg.internal.server.error",
                            parameters: []
                        }]);

                    })
                }

                function openImageModal(isPngFile, isJpegFile, isGifFile, data) {
                    $modal.open({
                        size: "xl",
                        template: modalTemplate,
                        controller: ["$scope", "$sce", function ($scope, $sce) {
                            $scope.trustSrc = function (src) {
                                return $sce.trustAsResourceUrl(src);
                            };
                            // $scope.isPdfFile = isPdfFile
                            // if ($scope.isPdfFile) {
                            //     $scope.objectModal = URL.createObjectURL(new Blob([data], {
                            //         type: "application/pdf"
                            //     }));
                            //     return
                            // }
                            $scope.isPngFile = isPngFile
                            if ($scope.isPngFile) {
                                $scope.objectModal = URL.createObjectURL(new Blob([data], {
                                    type: 'image/png'
                                }));
                                return
                            }
                            $scope.isJpegFile = isJpegFile
                            if ($scope.isJpegFile) {
                                $scope.objectModal = URL.createObjectURL(new Blob([data], {
                                    type: 'image/jpeg'
                                }));
                                return
                            }
                            $scope.isGifFile = isGifFile
                            if ($scope.isGifFile) {
                                $scope.objectModal = URL.createObjectURL(new Blob([data], {
                                    type: 'image/gif'
                                }));
                            }
                        }]
                    })
                }
            }]
        };
    }]);
});