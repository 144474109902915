define([
    "../monitorableModule",
    "text!./tpfModal.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.service("tpfModal", [
        "$modal",
        "monitoringService",
        "loadingService",
        "messagesModal",
        "remoteExceptionHandler",
        function ($modal, monitoringService, loadingService, messagesModal, remoteExceptionHandler) {
            return function (invoice) {
                return $modal.open({
                    size: "xl",
                    template: template,
                    controller: ["$scope", function ($scope) {
                        $scope.entity = {
                            cpf: null,
                            responsibleName: null,
                            responsibleEmail: null,
                            fileName: null,
                            fileBase64: null,
                            authorTP: null,
                            invoice: invoice
                        };

                        $scope.getAuthorTP = function () {
                            return ["Empresa Emitente", "Empresa destinatária", "Empresa", "Fisco", "RFB", "Outros Órgãos"]
                        }

                        $scope.sendToTPF = function () {
                            if (invoice.typeName === "INVOICE") {
                                $scope.entity.fileName = getFileName();
                                loadingService(monitoringService.sendToTPF($scope.entity))
                                    .then(function (response) {
                                        console.log(response);
                                        return messagesModal("dialog.success", {
                                            keyBundle: "monitoring.macro.save.success"
                                        });
                                    }).catch(remoteExceptionHandler());
                            }
                        }

                        function getFileName() {
                            if (!!$scope.entity.file) {
                                return $scope.entity.file.name;
                            }
                        }

                        $scope.doFile = function (element) {
                            $scope.file = element.files[0];
                            if ($scope.file.type !== "image/jpeg" && $scope.file.type !== "image/png") {
                                $scope.removeSignature();
                                return;
                            }
                            var reader = new FileReader();

                            reader.onload = function (event) {
                                $scope.entity.fileName = $scope.file.name;
                                $scope.entity.fileBase64 = event.target.result.replace(/data:(.*?);base64,/, "");
                            };
                            reader.readAsDataURL($scope.file)
                        }

                        $scope.isValid = function () {
                            return $scope.entity.responsibleName !== null &&
                                $scope.entity.responsibleName !== "" &&
                                $scope.entity.cpf !== null &&
                                $scope.entity.cpf !== "" &&
                                $scope.entity.authorTP !== null &&
                                $scope.entity.authorTP !== "" &&
                                $scope.entity.responsibleEmail !== null &&
                                $scope.entity.responsibleEmail !== "";
                        }
                    }]
                });
            };
        }]);
});
