define([
    "./occurrenceCategoryModule",
    "text!./search/OccurrenceCategorySearchView.html",
    "text!./edition/OccurrenceCategoryEditionView.html"
], function (module,
             occurrenceCategorySearchTemplate,
             occurrenceCategoryEditionTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.newOccurrenceCategory", {
                url: "/newOcccurrenceCategory",
                controller: "OccurrenceCategoryEditionController",
                template: occurrenceCategoryEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.category",
                    pageTitle: "{{'title.monitoring.newOccurrenceCategory' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCategory: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.getNewInstanceOccurrenceCategory();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceCategorySearch", {
                url: "/occurrenceCategory?reload",
                controller: "OccurrenceCategorySearchController",
                template: occurrenceCategorySearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.occurrence.category",
                    pageTitle: "{{'title.monitoringOccurrenceCategorySearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.OccurrenceCategoryEdition", {
                url: "/occurrenceCategory/:occurrenceCategoryId",
                controller: "OccurrenceCategoryEditionController",
                template: occurrenceCategoryEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.category",
                    pageTitle: "{{'title.monitoring.occurrenceCategoryEdition' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCategory: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findOccurrenceCategoryById($stateParams.occurrenceCategoryId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceCategoryCopy", {
                url: "/occurrenceCategory/copy/:occurrenceCategoryId",
                controller: "OccurrenceCategoryEditionController",
                template: occurrenceCategoryEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.category",
                    pageTitle: "{{'title.monitoring.occurrenceCategoryCopy' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCategory: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyOccurrenceCategory($stateParams.occurrenceCategoryId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
