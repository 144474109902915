define([
    "./trackingModule",
    "text!./MarkersPopupTemplate.html"
], function (trackingModule, MarkersPopupTemplate) {
    "use strict";

    trackingModule.service("truckMarkerPopupCreator", [
        "$http",
        "monitoringService",
        "trackingService",
        "markerService",
        "markerImage",
        function ($http, monitoringService, trackingService, markerService, markerImage) {
            return function (content) {
                if (content.lastPosition.signalTime != null) {
                    var iconUrl = markerImage.enumIconToIconFile(content.truckIcon);
                    let marker = markerService.createMarker(content.monitorableCode, content.markerId, content.lastPosition, iconUrl, {
                        template: MarkersPopupTemplate,
                        templateParameter: parameterBuilder(content)
                    });
                    return marker;
                }
                return null;
            };

            function parameterBuilder(content){
                if(content.isOrphanSignal){
                    return {
                        id: content.markerId,
                        lastUpdate: content.lastPosition.signalTime,
                        deviceId: content.deviceId,
                        isOrphanSignal: content.isOrphanSignal,
                        displayInfo:{
                            providerId: content.providerId,
                            deviceId: content.deviceId,
                            lastUpdate: (new Date(content.lastPosition.signalTime)).toLocaleString()
                        },
                    };
                }
                return {
                    id: content.markerId,
                    trip: content.monitorableCode,
                    type: content.type,
                    driver: content.driver, //used on monitorableTable
                    plate: content.currentPlate, //used on monitorableTable
                    lastUpdate: content.lastPosition.signalTime,
                    children: content.children,
                    isOrphanSignal: content.isOrphanSignal,
                    displayInfo: {
                        trip: content.monitorableCode,
                        vehicle: content.vehicle,
                        plate: content.currentPlate,
                        lastUpdate: (new Date(content.lastPosition.signalTime)).toLocaleString(),
                        lastTransition: content.lastTransition ? content.lastTransition.name : null,
                        destination: content.destination,
                        nextTransition: content.nextTransition ? content.nextTransition.name : null,
                        nextTransitionTime: (new Date(content.nextTransitionTime)).toLocaleString(),
                    },
                };
            };
        }
    ]);
});