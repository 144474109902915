define(["../occurrencesModule", "json!../../tdnByEntity.json"],
    function (occurrencesModule, tdnByEntity) {
        "use strict";

        return occurrencesModule.controller("MonitoringOccurrenceSearchController", [
            "$scope",
            "$q",
            "monitoringService",
            "reload",
            "loadingService",
            "remoteExceptionHandler",
            "occurrenceService",
            "monitorablesAndTransitionsDialog",
            "tdnHelp",
            function ($scope, $q, monitoringService, reload, loadingService, remoteExceptionHandler, occurrenceService, monitorablesAndTransitionsDialog, tdnHelp) {
                $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["occurrence-create"];
                $scope.serviceName = occurrenceService.getFilterServiceUrlWithMonitorable();
                var template = [], paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();

                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                $scope.newOccurrence = function () {
                    return occurrenceService.createNewOccurrence();
                };

                monitoringService.getContentExclusion().then(function (response) {
                    $scope.contentExclusion = response;
                });

                $q.all([paginationApiDefer.promise, filterApiDefer.promise]).then(function () {
                    filterApi.onChangeService(function (service) {
                        if (service === $scope.serviceName) {
                            if (reload) {
                                filterApi.reload();
                            } else {
                                filterApi.reset();
                            }
                        }
                    });
                });

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(occurrenceService.filterWithMonitorable(template, firstResult, maxResults))
                                .catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.inspectRecord = function (record) {
                    loadingService(occurrenceService.getMonitorables(record.restOccurrence.id))
                        .then(function (response) {
                            monitorablesAndTransitionsDialog(response);
                        }).catch(remoteExceptionHandler());
                };

                $scope.openTimeDuration = function (occurrence) {
                    return occurrenceService.openTimeDuration(occurrence);
                };

                $scope.durationInStatus = function (occurrence) {
                    return occurrenceService.durationInStatus(occurrence);
                };

                $scope.getIndicator = function (occurrence) {
                    return occurrenceService.getIndicator(occurrence);
                };

            }]);
    });